 /deep/ .el-input-number.is-without-controls .el-input__inner {
  text-align: left;
}
.templateList .framePage-body-inner {
  display: flex;
}
.templateList .framePage-body-inner .framePage-body-inner-item {
  width: 40%;
  min-width: 25rem;
}
.templateList .framePage-body-inner .framePage-body-inner-item .title {
  position: relative;
  padding-left: 1.25rem;
  line-height: 1.17rem;
  margin: 20px 0;
}
.templateList .framePage-body-inner .framePage-body-inner-item .title::after {
  content: "";
  display: block;
  width: 0.525rem;
  height: 1.17rem;
  position: absolute;
  left: 0;
  top: 0%;
  background-color: #5C6BE8;
}
.templateList .framePage-body-inner .framePage-body-inner-item .examInfo {
  padding-left: 40px;
}
.templateList .framePage-body-inner .framePage-body-inner-item .examInfo .personInifo-wrap {
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin: 10px 0;
  border: 1px solid #DCDFE6;
  padding: 5px 10px;
  border-radius: 4px;
  width: 100%;
}
.templateList .framePage-body-inner .framePage-body-inner-item .examInfo .personInifo-wrap .attrBox {
  width: 100%;
}
.templateList .framePage-body-inner .framePage-body-inner-item .examInfo .personInifo-wrap .label {
  width: 6rem;
  border-right: 1px solid #DCDFE6;
  padding-right: 10px;
  flex-shrink: 0;
}
.templateList .framePage-body-inner .framePage-body-inner-item .examInfo .personInifo-wrap .value {
  margin-left: 20px;
  color: #909399;
  min-width: calc(100% - 6rem - 20px);
}
.templateList .framePage-body-inner .framePage-body-inner-item:nth-child(2) {
  margin-left: 20px;
  min-width: 43rem;
}
.templateList .framePage-body-inner .framePage-body-inner-item .materialInfo {
  display: flex;
  flex-wrap: wrap;
}
.templateList .framePage-body-inner .framePage-body-inner-item .materialInfo .materialInfo-item {
  margin: 10px;
  cursor: pointer;
}
.templateList .framePage-body-inner .framePage-body-inner-item .materialInfo .materialInfo-item .img-wrap {
  text-align: center;
  width: 17.625rem;
  height: 8.5rem;
  border: 1px dashed #c0ccda;
  border-radius: 4px;
  overflow: hidden;
}
.templateList .framePage-body-inner .framePage-body-inner-item .materialInfo .materialInfo-item .img-wrap img {
  display: block;
  width: auto;
  height: 100%;
  margin: 0 auto;
}
.templateList .framePage-body-inner .framePage-body-inner-item .materialInfo .materialInfo-item .text {
  text-align: center;
  margin-top: 10px;
}
.templateList .framePage-body-inner .framePage-body-inner-item .materialInfo .materialInfo-item .text /deep/ .el-upload {
  border-width: 0!important;
  width: 80px!important;
  height: 30px!important;
}
.templateList .framePage-body-inner .framePage-body-inner-item .materialInfo .materialInfo-item .img-2c-wrap {
  width: 12rem;
  height: 16rem;
  border: 1px dashed #c0ccda;
  border-radius: 4px;
  overflow: hidden;
}
.templateList .btn-group {
  margin-top: 20px;
  padding-left: 12.5rem;
}
.templateList .btn-group .el-button {
  background-color: #5C6BE8;
  border: none;
}
.templateList .btn-group .el-button:hover {
  background-color: #6875ee;
}
.templateList .previewImg {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3000;
  display: flex;
  align-items: center;
  padding: 40px;
  box-sizing: border-box;
}
.templateList .previewImg .image {
  display: block;
  max-height: 100%;
  height: auto;
  max-width: 100%;
  width: auto;
  margin: 0 auto;
}
.templateList .fade-enter-active,
.templateList .fade-leave-active {
  transition: opacity .2s;
}
.templateList .fade-enter,
.templateList .fade-leave-to {
  opacity: 0;
}
.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
  background-color: transparent;
}
.docsDialog .el-form-item {
  margin-bottom: 5px;
}
.attrBox {
  display: flex;
  justify-content: flex-start;
}
.attrBox .label {
  display: flex;
  align-items: center;
}
.attrBox /deep/ .el-input-number .el-input__inner {
  text-align: left;
}
