


















































































































































































































































































































































































































































































































































































































































































/deep/.el-input-number.is-without-controls .el-input__inner{
    text-align: left;
}
    .templateList{
        .framePage-body-inner{
            display: flex;
            .framePage-body-inner-item{
                width: 40%;
                min-width: 25rem;
                .title{
                    position: relative;
                    padding-left: 1.25rem;
                    line-height: 1.17rem;
                    margin: 20px 0;
                    &::after{
                        content:"";
                        display:block;
                        width: 0.525rem;
                        height: 1.17rem;
                        position: absolute;
                        left: 0;
                        top: 0%;
                        background-color: #5C6BE8;
                    }
                }
                .examInfo{
                    padding-left:40px;
                    .personInifo-wrap{
                        display: flex;
                        align-items: center;
                        font-size: 1rem;
                        margin: 10px 0;
                        border: 1px solid #DCDFE6;
                        padding: 5px 10px;
                        border-radius: 4px;
                        width: 100%;
                        .attrBox{
                            width: 100%;
                        }
                        .label{
                            width: 6rem;
                            border-right: 1px solid #DCDFE6;
                            padding-right: 10px;
                            flex-shrink: 0;
                        }
                        .value{
                            margin-left:20px;
                            color:#909399;
                            min-width: calc(100% - 6rem - 20px);
                        }
                    }
                }
                &:nth-child(2){
                    margin-left:20px;
                    min-width: 43rem;
                }
                .materialInfo{
                    display: flex;
                    flex-wrap: wrap;
                    .materialInfo-item{
                        margin: 10px;
                        cursor: pointer;
                        .img-wrap{
                          text-align: center;
                            width: 17.625rem;
                            height: 8.5rem;
                            border: 1px dashed #c0ccda;
                            border-radius: 4px;
                            overflow: hidden;
                            img{
                                display: block;
                                width: auto;
                                height: 100%;
                                margin: 0 auto;
                            }
                        }
                        .text{
                            text-align: center;
                            margin-top: 10px;
                            /deep/ .el-upload{
                                border-width: 0!important;
                                width: 80px!important;
                                height: 30px!important;
                            }
                        }
                        .img-2c-wrap{
                            width: 12rem;
                            height: 16rem;
                            border: 1px dashed #c0ccda;
                            border-radius: 4px;
                            overflow: hidden;
                        }
                    }
                }
            }
        }
        .btn-group{
            margin-top: 20px;
            padding-left: 12.5rem;
            .el-button{
                background-color: #5C6BE8;
                border: none;
                &:hover{
                    background-color: #6875ee;
                }
            }
        }
        .previewImg{
            position: fixed;
            height: 100vh;
            width: 100vw;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 3000;
            display: flex;
            align-items: center;
            padding: 40px;
            box-sizing: border-box;
            .image{
                display: block;
                max-height: 100%;
                height: auto;
                max-width: 100%;
                width: auto;
                margin: 0 auto;
            }
        }
        .fade-enter-active, .fade-leave-active {
            transition: opacity .2s;
        }
        .fade-enter, .fade-leave-to {
            opacity: 0;
        }
    }
    .el-button.is-disabled,
    .el-button.is-disabled:focus,
    .el-button.is-disabled:hover {
      background-color: transparent;
    }
    .docsDialog .el-form-item {
      margin-bottom: 5px;
    }
    .attrBox{
      display: flex;
      justify-content: flex-start;
      .label{
        display: flex;
        align-items: center;
      }
      /deep/.el-input-number .el-input__inner{
        text-align: left;
      }
    }
